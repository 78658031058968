import { template as template_3596d5d323e54aecb9e5974dd3f3546e } from "@ember/template-compiler";
const FKText = template_3596d5d323e54aecb9e5974dd3f3546e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
